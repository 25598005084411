import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_statuses_list = _resolveComponent("statuses-list")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, { breadcrumbs: _ctx.breadcrumbs }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Statuses",
        icon: "flag",
        class: "mb-4"
      }),
      _createVNode(_component_statuses_list, {
        "delete-modal-title": "Delete ticket status",
        "item-name": "Ticket",
        bordered: "",
        statuses: _ctx.ticketsStatusOptionsComputed
      }, null, 8, ["statuses"])
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}