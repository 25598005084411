
import { defineComponent, computed } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import StatusesList from '@/components/pages/shared/StatusesList.vue'
import { ticketsStatusOptions } from '@/definitions/_general/_data/optionsList'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    StatusesList,
    PageContent,
    DetailsHero,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Statuses', url: '' },
    ]

    const ticketsStatusOptionsComputed = computed(() => {
      if (isEmptyMode.value) {
        return ticketsStatusOptions.filter((e: any) => e.isDefault)
      }
      return ticketsStatusOptions
    })

    return {
      breadcrumbs,
      ticketsStatusOptionsComputed,
    }
  },
})
